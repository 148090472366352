/* open sans google font */
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  * {
    scroll-behavior: smooth;
  }
  body {
    @apply font-open-sans;
  }
}

html {
  scroll-padding: 8rem;
}

.nav-link .active {
  @apply !text-brand-primary;
}

.swiper-pagination .swiper-pagination-bullet {
  @apply !w-4 !h-4 !bg-white opacity-40;
}

.swiper-pagination .swiper-pagination-bullet-active {
  @apply !bg-brand-primary opacity-100;
}
